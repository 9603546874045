import React, { useState, useEffect } from 'react';
import logo from './img/logo.svg';
import './scss/bootstrap.scss';
import './App.css';

function App() {
  const [token, setToken] = useState();
  const [errors, setErrors] = useState();
  const [loadingCompleted, setLoadingCompleted] = useState(false); 
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [data, setData] = useState({emailAddress: '', password: '', confirmPassword: ''});

  const onFormFieldUpdate = (e, name) => {
    var value = e.target.value;
    setData({ ...data, [name]: value });
  }

  const onSubmitButtonClick = () => {
    setLoading(true);
    fetch('https://ws-az-ae-mywestminster-api-signup.azurewebsites.net/api/signup?code=jjKSGuaPJFG7x_wkRELQ-rx_ZHgPXE_YZ9YTkEeuvBzwAzFuImaq5Q==', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...data, token })
    })
    .then(response => {
      if (!response.ok){
        response.json().then(r=> setErrors(r))
        setLoading(false);
        return;
      }
      setLoading(false);
      setCompleted(response.ok);
    });   
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const qsToken = urlParams.get('t');

    if (qsToken) {
      setToken(qsToken);
    }
    setLoadingCompleted(true);
  }, []);
  return (
    <div className="app d-flex justify-items-center justify-content-center align-items-center">
      <div className="container">
        <div className="row d-flex justify-items-center justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-start bg-white shadow p-5">     
            {token && loadingCompleted && <div className="row">
              {!completed ? <div className="col-12 px-3 text-start">
              <p className=""><img src={logo} style={{ height: 40 }} /></p>
                <p className="mt-4">Enter your details below to create a <b>My Westminster</b> account</p>
                {errors && errors.length > 0 &&
                <div className="alert alert-danger text-start rounded-0">
                  {errors.map((error) => <p className="m-0">{error}</p>)}
                </div>}
                <div className="text-start">
                    <div className="form-group">
                      <label htmlFor="emailAddress" className="my-2">Email address</label>
                      <input type="email" className="form-control rounded-0" id="emailAddress" aria-describedby="emailHelp" placeholder="Enter email" onChange={e=> onFormFieldUpdate(e, 'emailAddress')} value={data.emailAddress} />
                    </div>
                    <div className="form-group mt-4">
                      <label htmlFor="password" className="mt-2">Password</label>
                      <div className="my-2 mb-3 text-muted"><small>Must be at least 12 characters long and contain at least one letter, one number and one special character</small></div>
                      <input type="password" className="form-control" id="password" placeholder="Password" onChange={e=> onFormFieldUpdate(e, 'password')} value={data.password} />
                    </div>
                    <div className="form-group mt-4">
                      <label htmlFor="confirmPassword" className="my-2">Reenter your password</label>
                      <input type="password" className="form-control" id="confirmPassword" placeholder="Password" onChange={e=> onFormFieldUpdate(e, 'confirmPassword')} value={data.confirmPassword} />
                    </div>
                    <button type="submit" onClick={onSubmitButtonClick} className="btn btn-primary w-100 mt-4 py-2" disabled={loading}>{loading && <i className="fa-solid fa-circle-notch fa-spin me-2"></i>}Create My Account</button>        
                </div>
              </div> : <div className="col-12 px-3 text-center">
              <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
              <p className="mt-4 text-center">Your <b>My Westminster</b> account has been created</p>
              <a href="https://my.westminster.sa.edu.au" className="btn btn-primary w-100 mt-4 py-2">Click here to log in to My Westminster</a>        


                </div>}
              
            </div>}
            {!token && loadingCompleted && <div className="row">
            <div className="col-12 px-3 text-start">
              <p className=""><img src={logo} style={{ height: 40 }} /></p>
                <p className="mt-4">Enter your details below to create a <b>My Westminster</b> account</p>
              
                <div className="alert alert-danger text-start rounded-0">
                 <p className="m-0">Please click the link provided in your welcome email.</p>
                </div>
              </div></div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
